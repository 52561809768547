import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField
} from '@mui/material';
import { updateAssistantDetails } from '../../services/apiService'; // Import the API function

const EditAssistantModal = ({ open, handleClose, assistant }) => {
  const [agentFirstMessage, setAgentFirstMessage] = useState(assistant.agent_welcome_message || '');
  const [systemPrompt, setSystemPrompt] = useState(assistant.system_prompt || '');
  const [loading, setLoading] = useState(false);

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      // Call API to save the changes
      await updateAssistantDetails(assistant._id, agentFirstMessage, systemPrompt);
      alert('Assistant details updated successfully');
    } catch (error) {
      alert('Error updating assistant details');
    } finally {
      setLoading(false);
      handleClose(); // Close modal after saving
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Assistant</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            label="Agent Name"
            value={assistant.agent_name}
            fullWidth
            disabled
            margin="dense"
          />
          <TextField
            label="Agent First Message"
            value={agentFirstMessage}
            onChange={(e) => setAgentFirstMessage(e.target.value)}
            fullWidth
            margin="dense"
          />
          <TextField
            label="System Prompt"
            value={systemPrompt}
            onChange={(e) => setSystemPrompt(e.target.value)}
            fullWidth
            multiline
            rows={6} // Larger text area
            margin="dense"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSaveChanges} color="primary" disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAssistantModal;
