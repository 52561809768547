import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import Papa from 'papaparse';

const CsvPreviewModal = ({ open, onClose, csvData, downloadCsv }) => {
  // Show only the first 30 rows for preview
  const previewData = csvData.slice(0, 30);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>CSV Data Preview</DialogTitle>
      <DialogContent dividers>
        {previewData.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Number</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Tag</TableCell>
                  <TableCell>Custom Field</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {previewData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.number}</TableCell>
                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell>{row.company_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.tag}</TableCell>
                    <TableCell>
                      {typeof row.custom_field === 'object' && row.custom_field !== null
                        ? JSON.stringify(row.custom_field)
                        : row.custom_field}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No data available</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={downloadCsv}
          style={{ marginTop: 20 }}
        >
          Download Full CSV
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default CsvPreviewModal;
