import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Button,
  TablePagination,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getCampaigns } from '../actions/campaignActions';
import { format } from 'date-fns';
import CreateCampaignModal from './modals/CreateCampaignModal';
import CampaignDetailsModal from './modals/CampaignDetailsModal';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#333',
    color: '#fff',
  },
  table: {
    minWidth: 650,
    backgroundColor: '#333',
  },
  tableCell: {
    color: 'white',
    backgroundColor: '#666',
  },
  tableHeadCell: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#000',
  },
  createButton: {
    backgroundColor: '#00796b',
    color: '#fff',
    marginLeft: theme.spacing(2),
  },
  campaignNameCell: {
    cursor: 'pointer',
    '&:hover': {
      color: '#1e90ff', // Change text color on hover
      textDecoration: 'underline', // Add underline to indicate clickable
    },
  },
  refreshButton: {
    color: '#fff',
  },
  copyIcon: {
    cursor: 'pointer',
    color: '#fff',
    marginLeft: theme.spacing(0.5),
    fontSize: '0.8rem',
  },
  tablePagination: {
    color: '#fff',
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonSection: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Campaigns = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { campaigns, loading } = useSelector((state) => state.campaign);
  const contactLists = useSelector((state) => state.contact.contactLists) || [];
  const [modalOpen, setModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch campaigns when the component mounts
  useEffect(() => {
    const clientId = JSON.parse(localStorage.getItem('auth'))._id;
    dispatch(getCampaigns(clientId));
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateCampaign = () => {
    setModalOpen(true);
  };

  const handleViewDetails = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setDetailsModalOpen(true);
  };

  const handleRefresh = async () => {
    const clientId = JSON.parse(localStorage.getItem('auth'))._id;
    await dispatch(getCampaigns(clientId));
  };

  const filteredCampaigns = Array.isArray(campaigns) ? campaigns.filter((campaign) => {
    try {
      const campaignData = JSON.parse(campaign.responseFromCamp)?.response?.[0]?.data;
      return campaignData && campaignData.name.toLowerCase().includes(searchTerm.toLowerCase()) || campaignData.id.toLowerCase().includes(searchTerm.toLowerCase());
    } catch (error) {
      console.error("Error parsing campaign responseFromCamp:", error);
      return false;
    }
  }) : [];
  
  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.filterSection}>
        <TextField
          label="Search Campaigns"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          InputProps={{
            style: { color: '#fff' },
          }}
          style={{ backgroundColor: '#555', borderRadius: 4 }}
        />
        <Box className={classes.buttonSection}>
          <IconButton onClick={handleRefresh} className={classes.refreshButton}>
            <RefreshIcon />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            className={classes.createButton}
            onClick={handleCreateCampaign}
          >
            Create Campaign
          </Button>
        </Box>
      </Box>
      {loading && <LinearProgress color="secondary" />}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="campaigns table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>Campaign ID</TableCell>
              <TableCell className={classes.tableHeadCell}>Campaign Name</TableCell>
              <TableCell className={classes.tableHeadCell}>Lists</TableCell>
              <TableCell className={classes.tableHeadCell}>Scheduled Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCampaigns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((campaign) => {
              let responseData = {};
              let listNamesArray = '';
              let scheduledTime = '';

              try {
                if (campaign.responseFromCamp) {
                  responseData = JSON.parse(campaign.responseFromCamp)?.response?.[0]?.data || {};
                  listNamesArray = responseData.lists ? responseData.lists.map(listSid => contactLists.find(list => list.listSid === listSid)?.list_name || listSid).join(', ') : '';
                  scheduledTime = format(new Date(responseData.schedule?.send_at), 'do MMMM yyyy, hh:mm a');
                }
              } catch (error) {
                console.error("Error parsing responseFromCamp:", error);
              }

              return (
                <TableRow key={campaign._id}>
                  <TableCell className={classes.tableCell} style={{ overflow: 'auto' }}>
                    {campaign.campaignId.slice(0, 5)}...
                    <IconButton className={classes.copyIcon} onClick={() => handleCopyToClipboard(campaign.campaignId)}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className={`${classes.tableCell} ${classes.campaignNameCell}`}
                    style={{ overflow: 'auto' }}
                    onClick={() => handleViewDetails(campaign.campaignId)}
                  >
                    {responseData.name || 'N/A'}
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ overflow: 'auto' }}>{listNamesArray || 'N/A'}</TableCell>
                  <TableCell className={classes.tableCell} style={{ overflow: 'auto' }}>{scheduledTime}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCampaigns.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.tablePagination}
          style={{ color: '#fff' }}
        />
      </TableContainer>
      <CreateCampaignModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <CampaignDetailsModal modalOpen={detailsModalOpen} setModalOpen={setDetailsModalOpen} campaignId={selectedCampaignId} />
    </Box>
  );
};

export default Campaigns;
