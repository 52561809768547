import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  TextField,
  Button,
  Typography,
  Tabs,
  Tab,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogContent,
  DialogTitle,
  Modal,
  Avatar,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { getCampaigns, getReport } from '../actions/reportActions';
import { getContactLists } from '../actions/contactActions';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { CSVLink } from 'react-csv';
import CampaignDetailsModal from './modals/CampaignDetailsModal'; // Import the CampaignDetailsModal

const useStyles = makeStyles((theme) => ({

  root: {
    padding: theme.spacing(2),
    backgroundColor: '#333',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  filterSection: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2), // Adds gap between each filter item
    flexWrap: 'wrap',      // Allows wrapping on smaller screens
  },
  filterItem: {
    flex: '1 1 15%', // Adjust the size of each item to take up 15% of the width, can be modified as per need
    minWidth: '200px', // Ensure a minimum width so items don’t get too small
  },
  searchBox: {
    backgroundColor: '#333',
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& .MuiInputLabel-root': {
      color: '#aaa',
    },
  },
  minDurationBox: {
    backgroundColor: '#333',
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& .MuiInputLabel-root': {
      color: '#aaa',
    },
  },
  dropdown: {
    backgroundColor: '#333',
    '& .MuiSelect-root': {
      color: '#fff',
    },
    '& .MuiInputLabel-root': {
      color: '#aaa',
    },
  },
  sortDropdown: {
    width: '15%', // Adjusts the width of the sort dropdown
    backgroundColor: '#333',
    '& .MuiSelect-root': {
      color: '#fff',
    },
    '& .MuiInputLabel-root': {
      color: '#aaa',
    },
  },
  tabs: {
    marginBottom: theme.spacing(2),
    '& .MuiTab-root': {
      color: '#aaa',
    },
    '& .Mui-selected': {
      color: '#fff',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#fff',
    },
  },
  table: {
    backgroundColor: '#333',
  },
  tableHeadCell: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#000',
  },
  tableCell: {
    color: 'white',
    backgroundColor: '#333',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    textAlign: 'center',
  },
  downloadButton: {
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
  },
  textarea: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: '#555',
    color: '#fff',
  },
  chatContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  chatMessageBox: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: '75%',
  },
  aiMessage: {
    backgroundColor: '#DCF8C6',
    color: '#000',
    textAlign: 'left',
  },
  clientMessage: {
    backgroundColor: '#FFF',
    color: '#000',
    textAlign: 'left',
    marginLeft: 'auto',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: 24,
    height: 24,
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: '#333', // Dark background
    color: '#fff', // White text
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  button: {
    height: '100%', // Align the button with the rest of the inputs
    minWidth: '150px', // Ensure a reasonable button width
  },
  audioPlayerContainer: {
    position: 'relative',
    display: 'inline-block',
    width: '200px', // Base width of the player
    height: '30px',
    overflow: 'hidden',
    '&:hover': {
      width: '350px', // Expand width when hovered to show all controls
    },
    transition: 'width 0.3s ease',
  },
  audioPlayer: {
    width: '100%', // Let the player fit within the container
    height: '30px',
    backgroundColor: '#555',
    color: '#fff',
    outline: 'none',
  },
  loader: {
    width: '70px',
    height: '50px',
    boxSizing: 'border-box',
    background: `
      conic-gradient(from 135deg at top,#0000, #fff 1deg 90deg,#0000 91deg) right -20px bottom 8px/18px 9px,
      linear-gradient(#fff 0 0) bottom/100% 8px,
      #000`,
    backgroundRepeat: 'no-repeat',
    borderBottom: '8px solid #000',
    position: 'relative',
    animation: '$loaderAnimation 2s infinite linear',
  },
  loaderBefore: {
    content: '""',
    position: 'absolute',
    width: '10px',
    height: '14px',
    backgroundColor: 'lightblue',
    left: '10px',
    animation: '$loaderBeforeAnimation 2s infinite cubic-bezier(0,200,1,200)',
  },
  '@keyframes loaderAnimation': {
    '100%': { backgroundPosition: 'left -20px bottom 8px, bottom' },
  },
  '@keyframes loaderBeforeAnimation': {
    '0%, 50%': { bottom: '8px' },
    '90%, 100%': { bottom: '8.1px' },
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    textAlign: 'center',
  },
}));



const Report = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // Ensure `campaigns` is initialized as an array, defaulting to an empty array if undefined
  const { campaigns = [], report = [], loading, error } = useSelector((state) => state.report || { campaigns: [], report: [], loading: false, error: null });

  const contactLists = useSelector((state) => state.contact.contactLists) || [];
  const [searchTerm, setSearchTerm] = useState('');
  const [minDuration, setMinDuration] = useState('');
  const [campaign, setCampaign] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState([]);
  const [status, setStatus] = useState('idle');
  const [prompt, setPrompt] = useState('');
  const [proLoading, setProLoading] = useState(false);
  const [proData, setProData] = useState([]);
  const [oqModalOpen, setOqModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('none');
  const [summaryModalOpen, setSummaryModalOpen] = useState(false); // State for CampaignDetailsModal
  const [campaignDetails, setCampaignDetails] = useState(null); // State to store campaign summary data
  const [chatSearchTerm, setChatSearchTerm] = useState(''); // State to hold chat search term
  const [loadingCampaign, setLoadingCampaign] = useState(false); // New state for campaign loading
  const [selectedChatExplanation, setSelectedChatExplanation] = useState('');



  useEffect(() => {
    const fetchData = async () => {
      const clientId = JSON.parse(localStorage.getItem('auth'))._id;
      await dispatch(getContactLists(clientId));
      await dispatch(getCampaigns(clientId));
    };
    fetchData();
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMinDurationChange = (e) => {
    setMinDuration(e.target.value);
  };

  const handleCampaignChange = (e) => {
    setCampaign(e.target.value);
  };

  const handleFilter = async () => {
    const clientId = JSON.parse(localStorage.getItem('auth'))._id;
    // Safely find the campaign, making sure campaigns is always an array
    const selectedCampaign = Array.isArray(campaigns) && campaigns.find((camp) => camp.campaignId === campaign);

    if (!selectedCampaign) {
      setStatus('no-campaign');
      return;
    }

    const response = JSON.parse(selectedCampaign.responseFromCamp).response[0].data;
    const camp_name = response.name;

    if (!campaign) {
      setStatus('no-campaign');
      return;
    }

    setLoadingCampaign(true); // Set loading to true before fetching
    // setStatus('processing'); // Optional: you can show the CircularProgress loader with this status

    try {
      const reportResponse = await dispatch(getReport(clientId, campaign, camp_name));
      if (reportResponse && reportResponse.campaign_sid) {
        setCampaignDetails(reportResponse.campaign_sid);
        setSummaryModalOpen(true);
        setStatus('completed');
      } else {
        setCampaignDetails(null);
        setStatus('completed');
      }
    } catch (error) {
      console.error('Error fetching report:', error);
      setStatus('error');
    } finally {
      setLoadingCampaign(false); // Set loading to false after the data is fetched
    }
  };

  const handleTabChange = (event, newValue) => {
  setTabIndex(newValue);
};


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor((seconds / 2) / 60);
    const remainingSeconds = Math.floor((seconds / 2) % 60);
    return `${minutes}m ${remainingSeconds}s`;
  };

  const handleChatOpen = (call) => {
    console.log(call, 'call')
    if (call.chat) {
      const chatMessages = call.chat.split('|').map((message) => message.trim());
      setSelectedChat(chatMessages);
      setSelectedChatExplanation(call.objective_qualified_data?.explanation || '');  // Set explanation from objective_qualified_data
      setChatModalOpen(true);
    } else {
      console.warn("No chat data available");
    }
  };

  const handleChatClose = () => {
    setChatModalOpen(false);
    setSelectedChat([]);
  };

  const handleOqModalOpen = () => {
    setOqModalOpen(true);
  };

  const handleOqModalClose = () => {
    setOqModalOpen(false);
  };

  const handleGetData = async () => {
    setProLoading(true);
    try {
      const response = await fetch('https://application.glimpass.com/exotel/get-objective-qualified-lead', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          campaignId: campaign,
          prompt: prompt,
        }),
      });

      const result = await response.json();
      setProData(result); // Assuming the result is the data array
      setProLoading(false);
    } catch (error) {
      console.error('Error fetching objective qualified lead:', error);
      setProLoading(false);
    }
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleSummaryModalClose = () => {
    setSummaryModalOpen(false);
    setCampaignDetails(null);
  };

  const filteredReport = Array.isArray(report)
    ? report.filter((call) => {
      const matchesSearchTerm =
        (call.name?.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
        (call.number?.toLowerCase().includes(searchTerm.toLowerCase()) || '');

      const matchesMinDuration = !minDuration || call.on_call_duration / 2 >= minDuration;

      const matchesChatSearchTerm = chatSearchTerm
        ? call.chat && call.chat.toLowerCase().includes(chatSearchTerm.toLowerCase())
        : true; // Check if chat contains the search term

      switch (tabIndex) {
        case 1:
          return call.status === 'completed' && matchesSearchTerm && matchesMinDuration && matchesChatSearchTerm;
        case 2:
          return call.status === 'failed' || call.status === 'no-answer' || call.status === 'busy' && matchesSearchTerm && matchesMinDuration && matchesChatSearchTerm;
        // case 3:
        //   return call.status === 'no-answer' && matchesSearchTerm && matchesMinDuration && matchesChatSearchTerm;
        // case 4:
        //   return call.status === 'busy' && matchesSearchTerm && matchesMinDuration && matchesChatSearchTerm;
        // case 5:
        //   return call.on_call_duration > 30 && matchesSearchTerm && matchesMinDuration && matchesChatSearchTerm;
        case 3:
          return call.objective_qualified_data?.hotLead && matchesSearchTerm && matchesMinDuration;  // New filter for Interested tab
        case 4:
          return call.objective_qualified_data?.warmLead && matchesSearchTerm && matchesMinDuration;  // New filter for Interested tab
        case 5:
          return call.objective_qualified_data?.coldLead && matchesSearchTerm && matchesMinDuration;  // New filter for Interested tab
        case 6:
          return proData && matchesSearchTerm && matchesMinDuration && matchesChatSearchTerm;
           
        default:
          return matchesSearchTerm && matchesMinDuration && matchesChatSearchTerm;
      }
    })
    : [];


  const sortedReport = filteredReport.sort((a, b) => {
    if (sortOrder === 'highest') {
      return b.on_call_duration - a.on_call_duration;
    } else if (sortOrder === 'lowest') {
      return a.on_call_duration - b.on_call_duration;
    } else {
      return 0;
    }
  });

  const getDownloadButtonText = () => {
    switch (tabIndex) {
      case 1:
        return "Download CSV (Completed)";
      case 2:
        return "Download CSV (Failed)";
      case 3:
        return "Download CSV (Hot Lead)";
      case 4:
        return "Download CSV (Warm Lead)";
      case 5:
        return "Download CSV (Cold Lead)";
      case 6:
        return "Download CSV (OQ Leads)";
      default:
        return "Download CSV (All)";
    }
  };
  

  const renderNoDataMessage = () => {
    if (tabIndex === 0) {
      return (
        <Box className={classes.centered}>
          <Typography variant="h6" color="inherit">
            Data is being processed, please allow some time.
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box className={classes.centered}>
          <Typography variant="h6" color="inherit">
            No data found for the selected tab.
          </Typography>
        </Box>
      );
    }
  };

  const formatChatMessage = (message) => {
    const parts = message.split(':');
    const sender = parts[0].trim();
    const text = parts.slice(1).join(':').split('>').map((line, index) => <div key={index}>{line.trim()}</div>);
    return { sender, text };
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.filterSection}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          className={`${classes.searchBox} ${classes.filterItem}`} // Apply filterItem class
        />
        <TextField
          label="Min Call Duration (seconds)"
          variant="outlined"
          value={minDuration}
          onChange={handleMinDurationChange}
          className={`${classes.minDurationBox} ${classes.filterItem}`} // Apply filterItem class
        />
        <TextField
          label="Search Chat"
          variant="outlined"
          value={chatSearchTerm}
          onChange={(e) => setChatSearchTerm(e.target.value)}
          className={`${classes.searchBox} ${classes.filterItem}`} // Apply filterItem class
        />
        <FormControl variant="outlined" className={`${classes.sortDropdown} ${classes.filterItem}`}>
          <InputLabel>Sort By Duration</InputLabel>
          <Select value={sortOrder} onChange={handleSortOrderChange} label="Sort By Duration">
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="highest">Highest</MenuItem>
            <MenuItem value="lowest">Lowest</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={`${classes.dropdown} ${classes.filterItem}`}>
          <InputLabel>Campaign</InputLabel>
          <Select value={campaign} onChange={handleCampaignChange} label="Campaign">
            {Array.isArray(campaigns) && campaigns.map((camp, index) => {
              const response = JSON.parse(camp.responseFromCamp).response[0].data;
              return (
                <MenuItem key={index} value={camp.campaignId}>
                  {response.name}
                </MenuItem>
              );
            })}
          </Select>

        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          className={`${classes.button} ${classes.filterItem}`} // Apply filterItem class
        >
          Filter
        </Button>
      </Box>

      {loadingCampaign && (
  <Box className={classes.centered}>
    <div className={classes.loader}>
      <div className={classes.loaderBefore}></div>
    </div>
    <Typography variant="h6" color="inherit" style={{ marginTop: 16 }}>
      Loading campaign data...
    </Typography>
  </Box>
)}


      {status === 'no-campaign' && (
        <Box className={classes.centered}>
          <Typography variant="h6" color="inherit">
            Please select a campaign first.
          </Typography>
        </Box>
      )}
      {status === 'processing' && (
        <Box className={classes.centered}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" color="inherit" style={{ marginTop: 16 }}>
            Data is being processed, please allow some time.
          </Typography>
        </Box>
      )}
      {status === 'completed' && campaignDetails && (
        <CampaignDetailsModal
          modalOpen={summaryModalOpen}
          setModalOpen={handleSummaryModalClose}
          campaignId={campaignDetails}
        />
      )}
      {status === 'completed' && !campaignDetails && (
        <>
          <Box className={classes.topSection}>
          <Tabs
  value={tabIndex}
  onChange={handleTabChange}
  indicatorColor="primary"
  textColor="inherit"
  className={classes.tabs}
>
  <Tab label="All" />
  <Tab label="Completed" />
  <Tab label="Failed" />
  <Tab label="Hot Leads" />
  <Tab label="Warm Leads" />
  <Tab label="Cold Leads" />
  <Tab label="OQ Leads" />
</Tabs>

            {filteredReport.length > 0 && tabIndex !== 6 && (
              <Box className={classes.downloadButton}>
                <CSVLink data={filteredReport} filename="report.csv">
                  <Button variant="contained" color="primary" startIcon={<CloudDownloadIcon />}>
                    {getDownloadButtonText()}
                  </Button>
                </CSVLink>
              </Box>
            )}
          </Box>
          {tabIndex === 6 ? (
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOqModalOpen}
                  // disabled={proLoading}
                  disabled
                >
                  Get OQ Lead
                </Button>
                {proData.length > 0 && (
                  <Box className={classes.downloadButton}>
                    <CSVLink data={proData} filename="objective-qualified-lead.csv">
                      <Button variant="contained" color="secondary" startIcon={<CloudDownloadIcon />}>
                        Download CSV
                      </Button>
                    </CSVLink>
                  </Box>
                )}
              </Box>
              {proLoading && <CircularProgress sx={{ mt: 2 }} />}
              {proData.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 4 }}>
                  <Table className={classes.table} aria-label="OQ Leads table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeadCell}>Name</TableCell>
                        <TableCell className={classes.tableHeadCell}>From</TableCell>
                        <TableCell className={classes.tableHeadCell}>To</TableCell>
                        <TableCell className={classes.tableHeadCell}>Status</TableCell>
                        <TableCell className={classes.tableHeadCell}>On Call Duration</TableCell>
                        <TableCell className={classes.tableHeadCell}>Recording</TableCell>
                        <TableCell className={classes.tableHeadCell}>Date Created</TableCell>
                        <TableCell className={classes.tableHeadCell}>Chat</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {proData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((call, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>{call.name}</TableCell>
                          <TableCell className={classes.tableCell}>{call.caller_id}</TableCell>
                          <TableCell className={classes.tableCell}>{call.number}</TableCell>
                          <TableCell className={classes.tableCell}>{call.status}</TableCell>
                          <TableCell className={classes.tableCell}>{formatDuration(call.on_call_duration)}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {call.recording_url ? <a href={call.recording_url} target="_blank" rel="noopener noreferrer" style={{ color: '#00f' }}>Link</a> : 'No recording'}
                          </TableCell>
                          <TableCell className={classes.tableCell}>{new Date(call.date_created).toLocaleString()}</TableCell>
                          <TableCell className={classes.tableCell}>
                            <Button variant="contained" color="primary" onClick={() => handleChatOpen(call)}>View Chat</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={proData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              )}
            </Box>
          ) : (
            <>
              {filteredReport.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="report table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeadCell}>Name</TableCell>
                        <TableCell className={classes.tableHeadCell}>From</TableCell>
                        <TableCell className={classes.tableHeadCell}>To</TableCell>
                        <TableCell className={classes.tableHeadCell}>Status</TableCell>
                        <TableCell className={classes.tableHeadCell}>On Call Duration</TableCell>
                        <TableCell className={classes.tableHeadCell}>Recording</TableCell>
                        <TableCell className={classes.tableHeadCell}>Date Created</TableCell>
                        <TableCell className={classes.tableHeadCell}>Chat</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((call, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>{call.name}</TableCell>
                          <TableCell className={classes.tableCell}>{call.caller_id}</TableCell>
                          <TableCell className={classes.tableCell}>{call.number}</TableCell>
                          <TableCell className={classes.tableCell}>{call.status}</TableCell>
                          <TableCell className={classes.tableCell}>{formatDuration(call.on_call_duration)}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {call.recording_url ? <a href={call.recording_url} target="_blank" rel="noopener noreferrer" style={{ color: '#00f' }}>Link</a> : 'No recording'}
                          </TableCell>
                          <TableCell className={classes.tableCell}>{new Date(call.date_created).toLocaleString()}</TableCell>
                          <TableCell className={classes.tableCell}>
                            <Button variant="contained" color="primary" onClick={() => handleChatOpen(call)}>View Chat</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredReport.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              ) : renderNoDataMessage()}
            </>
          )}
        </>
      )}
      {/* OQ Modal */}
      <Modal
        open={oqModalOpen}
        onClose={handleOqModalClose}
      >
        <Box className={classes.modalStyle}>
          <Typography variant="h6" gutterBottom>
            Objective Qualified Lead
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Campaign: {Array.isArray(campaigns) ? campaigns.find(camp => camp.campaignId === campaign)?.responseFromCamp?.response?.[0]?.data?.name || '' : ''}
          </Typography>

          <TextField
            label="Search Prompt"
            multiline
            rows={4}
            variant="outlined"
            className={classes.textarea}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleGetData}
            disabled={proLoading}
          >
            {proLoading ? 'Fetching...' : 'Get Data'}
          </Button>
        </Box>
      </Modal>

      {/* Chat Modal */}
      <Dialog open={chatModalOpen} onClose={handleChatClose}>
        <DialogTitle>Chat
          {selectedChatExplanation && (
            <Typography variant="body2" style={{ marginTop: '10px', fontStyle: 'italic' }}>
              Explanation: {selectedChatExplanation}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {selectedChat.length > 0 ? (
            selectedChat.map((message, index) => {
              const { sender, text } = formatChatMessage(message);
              const isAI = sender === 'AI';
              return (
                <Box
                  key={index}
                  className={classes.chatContainer}
                  style={{ flexDirection: isAI ? 'row' : 'row-reverse' }}
                >
                  <Avatar className={classes.avatar}>
                    {isAI ? 'AI' : 'U'}
                  </Avatar>
                  <Box
                    className={`${classes.chatMessageBox} ${isAI ? classes.aiMessage : classes.clientMessage}`}
                  >
                    <Typography variant="body1">
                      {text}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography>No chat data available</Typography>
          )}
        </DialogContent>
      </Dialog>

    </Box>
  );
};

export default Report;
