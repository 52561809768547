import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBillingHistory, fetchClientData } from '../actions/billingActions';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Pagination,
    IconButton,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const Billing = () => {
    const dispatch = useDispatch();
    const { billingHistory, clientData, error } = useSelector((state) => state.billing);
    const [page, setPage] = useState(1); // Pagination state
    const itemsPerPage = 5; // Number of items per page

    // Get clientId from localStorage
    const clientId = JSON.parse(localStorage.getItem('auth'))?._id || '';

    useEffect(() => {
        if (clientId) {
            dispatch(fetchBillingHistory(clientId));
            dispatch(fetchClientData(clientId));
        }
    }, [dispatch, clientId]);

    // Handle refresh button click
    const handleRefresh = () => {
        if (clientId) {
            dispatch(fetchBillingHistory(clientId));
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const paginatedHistory = billingHistory.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Billing Information
            </Typography>
            <Box mb={3}>
                <Typography variant="h6">Client Name: {clientData.name}</Typography>
                <Typography variant="h6">Available Balance: {clientData.availableBalance}</Typography>
                <Typography variant="h6">Blocked Balance: {clientData.blockedBalance}</Typography>
                {/* <Typography variant="h6">Active Campaign: {clientData.isActiveCamp ? 'Yes' : 'No'}</Typography> */}
                <Typography variant="h6">Active Campaign ID: {clientData.activeCampId ? clientData.activeCampId : 'N/A'}</Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5" gutterBottom>
                    Billing History
                </Typography>
                <IconButton onClick={handleRefresh} sx={{ color: 'black' }}>
                    <RefreshIcon />
                </IconButton>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Transaction Type</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>New Available Balance</TableCell>
                            <TableCell>Campaign ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedHistory.map((record) => (
                            <TableRow key={record._id}>
                                <TableCell>{new Date(record.date).toLocaleString()}</TableCell>
                                <TableCell style={{ color: record.transactionType === 'Cr' ? 'green' : 'red' }}>
                                    {record.transactionType}
                                </TableCell>
                                <TableCell>{record.balanceCount}</TableCell>
                                <TableCell>{record.desc}</TableCell>
                                <TableCell>{record.newAvailableBalance}</TableCell>
                                <TableCell>{record.campaignId || 'N/A'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination Component */}
            <Box display="flex" justifyContent="center" mt={3}>
                <Pagination
                    count={Math.ceil(billingHistory.length / itemsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    sx={{
                        '& .MuiPaginationItem-root': {
                            color: 'black', // Make all pagination items black
                        },
                        '& .Mui-selected': {
                            backgroundColor: 'black', // Set selected page background color to black
                            color: 'white', // Set selected page text color to white
                        },
                        '& .MuiPaginationItem-icon': {
                            color: 'black', // Color the arrows (next/previous) black
                        },
                    }}
                />
            </Box>
        </Container>
    );
};

export default Billing;
