import React, { useState, useEffect } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Button,
  TablePagination,
  TextField,
  LinearProgress
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import { fetchAssistantsByClient } from '../services/apiService'; // API to fetch assistants
import EditAssistantModal from './modals/EditAssistantModal'; // The separated modal

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#333',
    color: '#fff',
  },
  table: {
    minWidth: 650,
    backgroundColor: '#333',
    marginTop: theme.spacing(2)
  },
  tableCell: {
    color: 'white',
    backgroundColor: '#666',
    fontWeight: 'bold',
  },
  tableHeadCell: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#000',
  },
  tablePagination: {
    color: '#fff',
  },
  searchBar: {
    marginBottom: theme.spacing(2),
    width: '300px', // Reduced width for a smaller search bar
    backgroundColor: '#555',
    color: '#fff',
    borderRadius: 4,
  },
}));

const MyAI = () => {
  const classes = useStyles();
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch Assistants by Client ID
  useEffect(() => {
    const fetchAssistants = async () => {
      setLoading(true);
      const clientId = JSON.parse(localStorage.getItem('auth'))._id;
      try {
        const response = await fetchAssistantsByClient(clientId);
        setAssistants(response.data || []);
      } catch (error) {
        console.error("Error fetching assistants", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAssistants();
  }, []);

  const handleEditClick = (assistant) => {
    setSelectedAssistant(assistant);
    setEditModalOpen(true);
  };

  const handleModalClose = () => {
    setEditModalOpen(false);
    setSelectedAssistant(null);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredAssistants = assistants.filter((assistant) => {
    const agentName = assistant.agent_name.toLowerCase();
    const id = assistant._id.toLowerCase();
    return (
      agentName.includes(searchTerm.toLowerCase()) ||
      id.includes(searchTerm.toLowerCase())
    );
  });

  return (
    <Box className={classes.root}>
      <TextField
        label="Search by _ID or Agent Name"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={classes.searchBar}
        InputLabelProps={{
          style: { color: '#fff' },
        }}
        InputProps={{
          style: { color: '#fff' },
        }}
      />
      {loading && <LinearProgress color="secondary" />}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="assistants table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>_ID</TableCell>
              <TableCell className={classes.tableHeadCell}>Agent Name</TableCell>
              <TableCell className={classes.tableHeadCell}>App ID</TableCell>
              <TableCell className={classes.tableHeadCell}>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAssistants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((assistant) => (
              <TableRow key={assistant._id}>
                <TableCell className={classes.tableCell}>
                  {assistant._id.slice(0, 5)}...
                  <IconButton onClick={() => handleCopyToClipboard(assistant._id)}>
                    <ContentCopyIcon fontSize="small" style={{ color: '#fff' }} />
                  </IconButton>
                </TableCell>
                <TableCell className={classes.tableCell}>{assistant.agent_name}</TableCell>
                <TableCell className={classes.tableCell}>{assistant.appId}</TableCell>
                <TableCell className={classes.tableCell}>
                  <IconButton onClick={() => handleEditClick(assistant)}>
                    <EditIcon fontSize="small" style={{ color: '#fff' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAssistants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.tablePagination}
        />
      </TableContainer>

      {/* Edit Assistant Modal */}
      {selectedAssistant && (
        <EditAssistantModal
          open={editModalOpen}
          handleClose={handleModalClose}
          assistant={selectedAssistant}
        />
      )}
    </Box>
  );
};

export default MyAI;
