import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
  Paper,
  Grid,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: '#333',
    color: '#fff',
    maxWidth: '800px',
    margin: 'auto',
  },
  profileItem: {
    marginBottom: theme.spacing(2),
    color: '#fff',
  },
  profileLabel: {
    fontWeight: 'bold',
    color: '#bbb',
  },
  profileValue: {
    color: '#fff',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '& fieldset': {
        borderColor: '#bbb',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
  copyIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    fontSize: '0.8rem',
    color: '#fff',
  },
  eyeButton: {
    color: '#fff',
  },
  paper: {
    padding: theme.spacing(3),
    backgroundColor: '#444',
    color: '#fff',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: theme.spacing(2),
  },
  idSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    color: '#fff',
  },
}));

const Profile = () => {
  const classes = useStyles();

  // Get data from localStorage (auth)
  const authData = JSON.parse(localStorage.getItem('auth')) || {};

  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Function to copy _id to clipboard
  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard!');
  };

  return (
    <Box className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        {/* _id Section at the top */}
        <Box className={classes.idSection}>
          <Typography variant="body1">
            <strong>Client ID:</strong> {authData._id} 
            <IconButton
              className={classes.copyIcon}
              onClick={() => handleCopyToClipboard(authData._id)}
            >
              <ContentCopyIcon />
            </IconButton>
          </Typography>
        </Box>

        <Typography variant="h5" gutterBottom>
          Profile Details
        </Typography>

        {/* Grid to display data in 2-3 columns per row */}
        <Box className={classes.gridContainer}>
          <Box>
            <Typography className={classes.profileLabel}>Name:</Typography>
            <Typography className={classes.profileValue}>{authData.name || 'N/A'}</Typography>
          </Box>

          <Box>
            <Typography className={classes.profileLabel}>Email:</Typography>
            <Typography className={classes.profileValue}>{authData.email || 'N/A'}</Typography>
          </Box>

          <Box>
            <Typography className={classes.profileLabel}>Company:</Typography>
            <Typography className={classes.profileValue}>{authData.company || 'N/A'}</Typography>
          </Box>

          <Box>
            <Typography className={classes.profileLabel}>Address:</Typography>
            <Typography className={classes.profileValue}>{authData.address || 'N/A'}</Typography>
          </Box>

          <Box>
            <Typography className={classes.profileLabel}>Contact:</Typography>
            <Typography className={classes.profileValue}>{authData.contact || 'N/A'}</Typography>
          </Box>


          <Box>
            <Typography className={classes.profileLabel}>Caller Numbers:</Typography>
            <Typography className={classes.profileValue}>{authData.callerNumbers?.join(', ') || 'N/A'}</Typography>
          </Box>

          <Box>
            <Typography className={classes.profileLabel}>Password:</Typography>
            <TextField
              type={showPassword ? 'text' : 'password'}
              value={authData.password || ''}
              className={classes.textField}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      className={classes.eyeButton}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Profile;
