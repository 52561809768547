import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Modal,
  Button,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignDetails } from '../../actions/campaignActions';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(4),
    width: '80%',
    backgroundColor: '#444',
    color: '#fff',
  },
  leftSection: {
    flex: 1,
    paddingRight: theme.spacing(2),
    borderRight: '1px solid #666',
  },
  rightSection: {
    flex: 1,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  campaignDetails: {
    marginBottom: theme.spacing(2),
  },
  detailItem: {
    marginBottom: theme.spacing(1),
    color: '#fff',
  },
  statusFlag: {
    display: 'inline-block',
    padding: theme.spacing(1),
    borderRadius: 4,
    color: '#fff',
    marginBottom: theme.spacing(2),
  },
  completed: {
    backgroundColor: '#4caf50',
  },
  failed: {
    backgroundColor: '#f44336',
  },
  scheduled: {
    backgroundColor: '#ffeb3b',
    color: '#000',
  },
  paused: {
    backgroundColor: '#2196f3',
  },
  in_progress: {
    backgroundColor: '#4caf50',
  },
  retriesSection: {
    marginTop: theme.spacing(2),
  },
  retriesItem: {
    marginBottom: theme.spacing(1),
  },
  pieChartContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    backgroundColor: '#555',
    padding: theme.spacing(2),
    borderRadius: 8,
    marginBottom: theme.spacing(2),
  },
  pieChart: {
    width: '100%',
    height: 300,
  },
  refreshButton: {
    backgroundColor: '#00796b',
    color: '#fff',
    marginTop: theme.spacing(2),
  },
}));

const CampaignDetailsModal = ({ modalOpen, setModalOpen, campaignId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const campaignDetails = useSelector((state) => state.campaign.campaignDetails);

  const fetchCampaignDetails = async () => {
    setLoading(true);
    try {
      await dispatch(getCampaignDetails(campaignId));
    } catch (error) {
      console.error('Error fetching campaign details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modalOpen && campaignId) {
      fetchCampaignDetails();
    }
  }, [modalOpen, campaignId]);

  if (!campaignDetails) return null;

  const statusClassMap = {
    Completed: classes.completed,
    Failed: classes.failed,
    Scheduled: classes.scheduled,
    Paused: classes.paused,
    InProgress: classes.in_progress,
  };

  const data = {
    labels: [
      'Completed', 
      'Failed', 
      'In Progress', 
      'Retrying', 
      'Invalid', 
      'Pending', 
      'Failed DND'
    ],
    datasets: [
      {
        label: 'Campaign Stats',
        data: [
          campaignDetails?.data?.stats?.completed || 0,
          campaignDetails?.data?.stats?.failed || 0,
          campaignDetails?.data?.stats?.in_progress || 0,
          campaignDetails?.data?.stats?.retrying || 0,
          campaignDetails?.data?.stats?.invalid || 0,
          campaignDetails?.data?.stats?.pending || 0,
          campaignDetails?.data?.stats?.['failed-dnd'] || 0, // Failed DND handling
        ],
        backgroundColor: [
          '#4caf50', 
          '#f44336', 
          '#ffeb3b', 
          '#2196f3', 
          '#9e9e9e', 
          '#673ab7', 
          '#ff5722'
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: '#fff',
        },
      },
    },
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      className={classes.modal}
    >
      <Box className={classes.paper}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box className={classes.leftSection}>
              <Box
                className={`${classes.statusFlag} ${
                  statusClassMap[campaignDetails?.data?.status] || ''
                }`}
              >
                {campaignDetails?.data?.status}
              </Box>
              <Box className={classes.campaignDetails}>
                <Typography variant="body1" className={classes.detailItem}>
                  <strong>ID:</strong> {campaignDetails?.data?.id}
                </Typography>
                <Typography variant="body1" className={classes.detailItem}>
                  <strong>Name:</strong> {campaignDetails?.data?.name}
                </Typography>
                <Typography variant="body1" className={classes.detailItem}>
                  <strong>Caller ID:</strong> {campaignDetails?.data?.caller_id}
                </Typography>
                <Typography variant="body1" className={classes.detailItem}>
                  <strong>Scheduled Time:</strong> {new Date(campaignDetails?.data?.schedule?.send_at).toLocaleString()}
                </Typography>
                <Typography variant="body1" className={classes.detailItem}>
                  <strong>End Time:</strong> {new Date(campaignDetails?.data?.schedule?.end_at).toLocaleString()}
                </Typography>
              </Box>
              <Box className={classes.retriesSection}>
                <Typography variant="h6" gutterBottom>
                  Retry Details
                </Typography>
                <Typography variant="body1" className={classes.retriesItem}>
                  <strong>Number of Retries:</strong> {campaignDetails?.data?.retries?.number_of_retries || 0}
                </Typography>
                <Typography variant="body1" className={classes.retriesItem}>
                  <strong>Mechanism:</strong> {campaignDetails?.data?.retries?.mechanism || 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.rightSection}>
              <Typography variant="h6" align="center" gutterBottom>
                Campaign Stats
              </Typography>
              <Box className={classes.pieChartContainer}>
                <div className={classes.pieChart}>
                  <Pie data={data} options={options} />
                </div>
              </Box>
              <Button
                variant="contained"
                onClick={fetchCampaignDetails}
                className={classes.refreshButton}
              >
                Refresh
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CampaignDetailsModal;
