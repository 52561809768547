import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Papa from 'papaparse';
import { format } from 'date-fns';
import { getContactLists, uploadCsv } from '../actions/contactActions';
import { fetchContactsFromList } from '../services/apiService';
import CsvPreviewModal from './modals/CsvPreviewModal';  // CSV Preview Modal
import CsvUploadModal from './modals/CsvUploadModal';    // CSV Upload Modal

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#333',
    color: '#fff',
  },
  table: {
    minWidth: 650,
    backgroundColor: '#888',
  },
  tableCell: {
    color: '#fff',
    backgroundColor: '#666',
  },
  tableHeadCell: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#000',
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3), // Adding bottom margin for spacing below search bar
  },
  searchBox: {
    width: '30%',
    '& .MuiInputBase-root': {
      color: '#fff',
    },
    '& .MuiInputLabel-root': {
      color: '#bbb',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#bbb',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
  uploadButton: {
    backgroundColor: '#00796b',
    color: '#fff',
  },
}));

const ContactList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contactLists = useSelector((state) => state.contact.contactLists) || [];
  
  // States for Upload CSV Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [listName, setListName] = useState('');
  
  // States for Search and Pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // States for CSV Preview Modal
  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const [selectedListData, setSelectedListData] = useState([]);

  useEffect(() => {
    const clientId = JSON.parse(localStorage.getItem('auth'))._id;
    dispatch(getContactLists(clientId));
  }, [dispatch]);

  const fetchContactsList = async (listSid) => {
    try {
      const response = await fetchContactsFromList(listSid);
      const data = response.data;

      if (data.response) {
        const contacts = data.response.map((item) => item.data);
        setSelectedListData(contacts);
      } else {
        setSelectedListData([]);
      }

      setCsvModalOpen(true);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setSelectedListData([]);
    }
  };

  const handleViewCsvModalOpen = (listSid) => {
    fetchContactsList(listSid);
  };

  const downloadCsv = () => {
    const csvData = Papa.unparse(selectedListData, {
      header: true,
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'contacts.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleViewCsvModalClose = () => {
    setCsvModalOpen(false);
    setSelectedListData([]);
  };

  // Handle file and list name changes for Upload Modal
  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleCsvUpload = async () => {
    if (!csvFile || !listName) {
      alert('Please provide both the list name and select a CSV file.');
      return;
    }
    
    const formData = new FormData();
    formData.append('file_name', csvFile);
    formData.append('list_name', listName);
    formData.append('type', 'static');

    // Your CSV upload logic using Papa.parse here
    Papa.parse(csvFile, {
      complete: async (results) => {
        // CSV upload logic
        if (results.data.length > 0) {
          try {
            await dispatch(uploadCsv(formData));
            setModalOpen(false);
            alert('CSV uploaded successfully!');
          } catch (error) {
            console.error('Error uploading CSV:', error);
          }
        } else {
          alert('CSV is empty or not in the correct format.');
        }
      },
      header: true,
    });
  };

  const filteredContactLists = Array.isArray(contactLists) && contactLists.length > 0
    ? contactLists.filter(
        (list) =>
          list.list_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          list.listSid.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <Box className={classes.root}>
      <Box className={classes.filterSection}>
        <TextField
          label="Search by Name or SID"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          className={classes.searchBox}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.uploadButton}
          onClick={() => setModalOpen(true)}
        >
          Upload CSV
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="contact list table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>List SID</TableCell>
              <TableCell className={classes.tableHeadCell}>List Name</TableCell>
              <TableCell className={classes.tableHeadCell}>Date Created</TableCell>
              <TableCell className={classes.tableHeadCell}>Contact Count</TableCell>
              <TableCell className={classes.tableHeadCell}>View CSV</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContactLists
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((list) => (
                <TableRow key={list._id}>
                  <TableCell className={classes.tableCell}>
                    {list.listSid.slice(0, 5)}...
                    <IconButton
                      className={classes.copyIcon}
                      onClick={() => handleCopyToClipboard(list.listSid)}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.tableCell}>{list.list_name}</TableCell>
                  <TableCell className={classes.tableCell}>
                    {format(new Date(list.response.list.date_created), 'do MMMM yyyy, hh:mm a')}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {list.listSize !== null ? list.listSize : 'N/A'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleViewCsvModalOpen(list.listSid)}
                    >
                      View CSV
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredContactLists.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ color: '#fff' }}
      />
      {/* Separate CSV Preview Modal */}
      <CsvPreviewModal
        open={csvModalOpen}
        onClose={handleViewCsvModalClose}
        csvData={selectedListData}
        downloadCsv={downloadCsv}
      />

      {/* CSV Upload Modal */}
      <CsvUploadModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        listName={listName}
        setListName={setListName}
        handleFileChange={handleFileChange}
        handleCsvUpload={handleCsvUpload}
      />
    </Box>
  );
};

export default ContactList;
