import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/authActions';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText,
  AppBar, Toolbar, Typography, CssBaseline, Hidden,
  IconButton, Box, Menu, MenuItem, Avatar, Divider
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Person, AccountCircle, ExitToApp, Menu as MenuIcon,
  ContactMail, Flag, Assessment as ReportIcon,
  PhoneInTalk as IncomingCallIcon, // Icon for Incoming Calls
  Receipt as BillingIcon // New Icon for Billing
} from '@mui/icons-material';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import HomePage from './HomePage';
import Profile from './Profile';
import Account from './Account';
import ContactList from './ContactList';
import Campaigns from './Campaign'; 
import Billing from './billing'
import Report from './Report';
import IncomingCalls from './IncomingCalls'; // Import IncomingCalls component
import { makeStyles } from '@material-ui/core/styles';

// Import the image
import markaibleLogo from '../assets/markaibleLogoWBG.png'; 
import MyAI from './MyAI';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    backgroundColor: theme.palette.background.default, 
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.palette.background.paper, 
    color: theme.palette.text.primary,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.paper, 
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  bottomList: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  emailBox: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  logo: {
    width: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
}));

const Dashboard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = () => {
    dispatch(logout());
    history.push('/login');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMobileOpen(false); // Close the drawer after selection
  };

  const email = JSON.parse(localStorage.getItem('auth'))?.email || 'user@example.com';

  const menuItems = [
    { text: 'Overview', icon: <DashboardIcon />, path: '/' },
    { text: 'My AI', icon: <Person />, path: '/my-ai' }, // Add My AI section
    { text: 'Contacts', icon: <ContactMail />, path: '/list' },
    { text: 'Campaigns', icon: <Flag />, path: '/campaign' }, 
    { text: 'Incoming Calls', icon: <IncomingCallIcon />, path: '/incoming-calls' }, // Incoming Calls added
    { text: 'Reports', icon: <ReportIcon />, path: '/report' }, // Reports moved below Incoming Calls
  ];

  const drawer = (
    <div>
      {/* Logo Section */}
      <Box display="flex" justifyContent="center">
        <img src={markaibleLogo} alt="Markaible Logo" className={classes.logo} />
      </Box>

      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={Link}
            to={item.path}
            onClick={handleMenuClose} // Close drawer on item click
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <div className={classes.bottomList}>
        <Divider />
        <Box className={classes.emailBox} onClick={handleMenuOpen}>
          <Avatar className={classes.avatar}>{email.charAt(0).toUpperCase()}</Avatar>
          <Typography variant="body2" noWrap>{email}</Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem component={Link} to="/billing" onClick={handleMenuClose}>
            <ListItemIcon><BillingIcon /></ListItemIcon>
            <ListItemText primary="Billing" />
          </MenuItem>
          <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
            <ListItemIcon><Person /></ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon><ExitToApp /></ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/profile" component={Profile} />
          <Route path="/account" component={Account} />
          <Route path="/list" component={ContactList} />
          <Route path="/campaign" component={Campaigns} />
          <Route path="/report" component={Report} />
          <Route path="/billing" component={Billing} />
          <Route path="/incoming-calls" component={IncomingCalls} /> {/* Add Incoming Calls */}
          <Route path="/my-ai" component={MyAI} />

        </Switch>
      </main>
    </div>
  );
};

export default Dashboard;
