import React from 'react';
import { Box, Button, Typography, Modal, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import sampleCsv from '../../assets/sample.csv';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(4),
    width: '50%',
    backgroundColor: '#555',
    color: '#fff',
  },
  note: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sampleCsv: {
    marginTop: theme.spacing(2),
    backgroundColor: '#666',
    padding: theme.spacing(2),
    borderRadius: 4,
    color: '#fff',
    fontFamily: 'monospace',
  },
  uploadButton: {
    marginTop: 20,
    backgroundColor: '#00796b',
    color: '#fff',
  },
}));

const CsvUploadModal = ({ open, onClose, listName, setListName, handleFileChange, handleCsvUpload }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modalContainer} // Add centering styles here
    >
      <Box className={classes.paper}>
        <Typography variant="h6">Upload CSV</Typography>
        <Typography variant="body2" className={classes.note}>
          Please ensure your CSV file is in the following format (all fields are required):
          <Box component="pre" className={classes.sampleCsv}>
            number,first_name,last_name,company_name,email,tag,custom_field
          </Box>
        </Typography>
        <a href={sampleCsv} download style={{ color: 'cyan' }}>
          Download sample.csv
        </a>
        <TextField
          label="List Name"
          variant="outlined"
          fullWidth
          value={listName}
          onChange={(e) => setListName(e.target.value)}
          style={{ marginBottom: 20, color: '#fff' }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          InputProps={{
            style: { color: '#fff' },
          }}
          required
        />
        <TextField
          type="file"
          variant="outlined"
          fullWidth
          onChange={handleFileChange}
          style={{ color: '#fff' }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          InputProps={{
            style: { color: '#fff' },
          }}
          required
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCsvUpload}
          className={classes.uploadButton}
          fullWidth
        >
          Upload
        </Button>
      </Box>
    </Modal>
  );
};

export default CsvUploadModal;
